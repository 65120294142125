const $recruitNavOpenKey1 = $('._nav1-button');
const $recruitNavOpenKey2 = $('._nav2-button');

const $recruitNav1 = $('._nav1-area');
const $recruitNav2 = $('._nav2-area');


$recruitNavOpenKey1.on('click', function () {
  if (!$recruitNav1.hasClass('open') && !$recruitNavOpenKey1.hasClass('open')) {
    $recruitNav1.addClass('open');
    $recruitNavOpenKey1.addClass('open');
  } else {
    recruitNavcloseAction1();
  }
});

function recruitNavcloseAction1() {
  if ($recruitNav1.hasClass('open') && $recruitNavOpenKey1.hasClass('open')) {
    $recruitNav1.removeClass('open');
    $recruitNavOpenKey1.removeClass('open');
  }
}


$recruitNavOpenKey2.on('click', function () {
  if (!$recruitNav2.hasClass('open') && !$recruitNavOpenKey2.hasClass('open')) {
    $recruitNav2.addClass('open');
    $recruitNavOpenKey2.addClass('open');
  } else {
    recruitNavcloseAction2();
  }
});

function recruitNavcloseAction2() {
  if ($recruitNav2.hasClass('open') && $recruitNavOpenKey2.hasClass('open')) {
    $recruitNav2.removeClass('open');
    $recruitNavOpenKey2.removeClass('open');
  }
}