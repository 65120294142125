const tabs = $('[role="tab"]');
const tabList = $('[role="tablist"]');

// 各タブにクリックイベントを追加
tabs.on("click", changeTabs);

// 各タブ間の矢印キーでの移動を有効化
let tabFocus = 0;
tabList.on("keydown", function(e) {
  if (e.keyCode === 39 || e.keyCode === 37) {
    tabs.eq(tabFocus).attr("tabindex", -1);
    // 左右矢印での移動。剰余演算でループさせる
    if (e.keyCode === 39) {
      tabFocus = (tabFocus + 1) % tabs.length;
    } else if (e.keyCode === 37) {
      tabFocus = (tabFocus - 1 + tabs.length) % tabs.length;
    }
    tabs.eq(tabFocus).attr("tabindex", 0).focus();
  }
});

// タブ切り替え用の関数
function changeTabs(e) {
  const target = $(e.currentTarget);
  const parent = target.parent();
  const grandparent = parent.parent();
  // ボタンの選択を切り替え
  parent.find('[aria-selected="true"]').attr("aria-selected", false);
  target.attr("aria-selected", true);
  // パネルの表示を切り替え
  grandparent.find('[role="tabpanel"]').attr("hidden", true);
  grandparent.find(`#${target.attr("aria-controls")}`).removeAttr("hidden");
}