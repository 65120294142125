import "./modules/smoothScroll";
import "./modules/gnavi";
import "./modules/slider";
import "./modules/pageTop";
import "./modules/pageOpen";
import "./modules/header";
import "./modules/recruitNavi";
import "./modules/tab";
import "./modules/modal";

$(function() {
  // ローカルナビカレント
  const navLink = $(".article-nav .article-nav-link");
  // クリック制御
  $.each(navLink, function() {
    const parent = $(this).closest(".article-nav-item");
    $(this).on("click", function(){
      parent.addClass("_current");
      parent.siblings().removeClass("_current");
    });
    $(".page-top-button").on("click", function(){
      navLink.closest(".article-nav-item").removeClass("_current");
    });
  });
  // スクロール制御
  $(window).on("load scroll resize", function () {
    var scrollTop = $(window).scrollTop();
    var windowHeight = $(window).height();
    $(".article-nav-link").closest(".article-nav-item").removeClass("_current");
    $('.js-section').each(function (i) {
        var secOffsetTop = $(this).offset().top;
        var id = $(this).attr('id');
        if (scrollTop > secOffsetTop  - windowHeight + (windowHeight - 200)) {
            $(".article-nav-link").closest(".article-nav-item").removeClass("_current");
            var link = $(".article-nav-link[href *= " + id +"]");
            $(link).closest(".article-nav-item").addClass("_current");
        }
    });
  });

  // お知らせページネーション
  let current_page = 1;
  let max_item = 5;
  let max_page = 5;
  let item_num = $(".news-list .news-list-row").length;
  let all_page = Math.ceil(item_num / max_item);

  initial(all_page);

  function initial(all_page) {
    if (all_page > 1) {
      let pagination_html = "";
      pagination_html = '<button class="pagination-btn pagination-prev"><span class="pagination-btn-text">前ページ</span></button>';
      pagination_html += '<ul class="pagination-list">';
      for (let i = 1; i <= max_page && i <= all_page; i++) {
        pagination_html += '<li class="pagination-list-item">';
        pagination_html += '<button class="pagination-list-btn js_page_switch" data-index="' + i + '">' + i + "</button>";
        pagination_html += "</li>";
      }
      pagination_html += '</ul>';
      pagination_html += '<button class="pagination-btn pagination-next"><span class="pagination-btn-text">次ページ</span></button>';
      $(".pagination").html(pagination_html);
      switch_page(current_page);
    } else {
      $(".news-page .news-list-row").addClass("is-active");
    }
  }

  $(document).on("click", ".js_page_switch", function () {
    current_page = $(this).data("index");
    switch_page(current_page);
    paginationTop();
  });

  $(document).on("click", ".pagination-prev", function () {
    if (current_page > 1) {
      current_page--;
      switch_page(current_page);
      paginationTop();
    }
  });

  $(document).on("click", ".pagination-next", function () {
    if (current_page < all_page) {
      current_page++;
      $(".js_page_switch[data-index=" + current_page + "]").trigger("click");
      paginationTop();
    }
  });

  function switch_page(current_page) {
    $(".js_page_switch").removeClass("is-active");
    $(".news-page .news-list-row").removeClass("is-active");
    $(".js_page_switch[data-index=" + current_page + "]").addClass("is-active");
    const start = max_item * (current_page - 1);
    for (var i = start; i < start + max_item; i++) {
      $(".news-page .news-list-row").eq(i).addClass("is-active");
    }

    if(current_page === 1) {
      $(".pagination-prev").css("visibility", "hidden");
      $(".pagination-next").css("visibility", "visible");
    } else if (current_page === all_page) {
      $(".pagination-prev").css("visibility", "visible");
      $(".pagination-next").css("visibility", "hidden");
    } else {
      $(".pagination-prev").css("visibility", "visible");
      $(".pagination-next").css("visibility", "visible");
    }
  }
  function paginationTop() {
    $('html, body').animate({scrollTop:0},'fast');
    return false;
  }
});
