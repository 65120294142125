import MicroModal  from 'micromodal';

const microModal = new MicroModal.init({
  onShow: (target, trigger, event) => {
    const image = target.querySelector('#modal-image');
    const caption = target.querySelector('#modal-image-caption');
    const path = event.target.getAttribute('src');
    const alt = event.target.getAttribute('alt');
    image.setAttribute('src', path);
    caption.innerText = alt;
  },
  // disableScroll: true,
  awaitCloseAnimation: true,
});