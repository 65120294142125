function slideShow() {
  let id;

  const $container = $(".top-mv-bg");
  const $images = $container.find("picture");

  let currentImageIndex = 0;

  $images.eq(currentImageIndex).addClass("current");

  function start(interval = 5000) {
    if (id === undefined) {
      id = setInterval(function(){
        currentImageIndex++;
        if (currentImageIndex === $images.length) {
          currentImageIndex = 0;
        }

        $container
        .find("picture.current.fade-out")
        .removeClass("fade-out")
        .removeClass("current");

        $container.find("picture.current").addClass("fade-out");

        $images.eq(currentImageIndex).addClass("current");
      },interval);
    }
  }

  function stop() {
    if (id !== undefined){
      clearInterval(id);
    }
  }

  return{start,stop};
}

// 画面初期表示の黒背景の表示時間
setTimeout(() => {
  slideShow().start(8000);
},1000);

const $container = $(".top-mv-bg");
const $images = $(".top-mv-bg picture");
const $contentAfter = $("._top01");

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if(entry.isIntersecting) {
        window.addEventListener("scroll",parallax);
      } else {
        $images.css("translate","0");
        window.removeEventListener("scroll",parallax);
      }
    });
  },
  {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  }
);
if (document.querySelector(".top-mv")){
  observer.observe(document.querySelector(".top-mv"));
}

function parallax() {
  const windowHeight = $(window).innerHeight();
  const scrollTop = $(window).scrollTop();
  const offsetTop = $container.offset().top;

  const y = scrollTop - offsetTop;
  if (y > 0){
    const translateY = Math.min(400,y * 0.65);
    $images.css("translate","0 " + translateY + "px");
  } else {
    const translateY2 = Math.min(-0,y * 0.65);
    $images.css("translate","0 " + translateY2 + "px");
  }
}