const mediaQuery = window.matchMedia('(min-width: 769px)')

$(document).ready(function () {
  const pagetop = $(".page-top-button-wrapper");
  pagetop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
});

$(window).scroll(function () {
  const scrollHeight = $(document).height();
  const scrollPosition = $(window).height() + $(window).scrollTop();
  const footHeight = $("#placeholder-footer").innerHeight();
  if (mediaQuery.matches) {
    if (scrollHeight - scrollPosition <= footHeight + 50) {
      $(".page-top-button-wrapper").addClass("is-stop");
    } else {
      $(".page-top-button-wrapper").removeClass("is-stop");
    }
  } else {
    if (scrollHeight - scrollPosition <= footHeight + 15) {
      $(".page-top-button-wrapper").addClass("is-stop");
    } else {
      $(".page-top-button-wrapper").removeClass("is-stop");
    }
  }
});