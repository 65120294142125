const header = $('#placeholder-header');

$('a[href^="#"]').on('click', function() {
  const gap = header.outerHeight();
  const speed = 500;
  const href = $(this).attr("href");
  const target = $(href == "#" || href == "" ? "html" : href);
  const position = target.offset().top - gap;

  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});

import Lenis from '@studio-freight/lenis'

const lenis = new Lenis();

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);