const $openKey = $('.gnavi-recruit-button');
const $openKey_sp = $('.gnavi-button');
const $gnavi_area = $('.gnavi-recruit-area');
const $gnavi_area_sp = $('.gnavi-area');
const $gnavi_button = $('.gnavi-recruit-button');
const $gnavi_button_sp = $('.gnavi-button');
const $gnavi_wrap = $('.gnavi-on-wrap');

$openKey.on('click', function () {
  if (!$gnavi_area.hasClass('open') && !$gnavi_button.hasClass('open')) {
    $gnavi_area.addClass('open');
    $gnavi_button.addClass('open');
    $gnavi_wrap.addClass('open');
  } else {
    closeAction();
  }
});

$openKey_sp.on('click', function () {
  if (!$gnavi_area_sp.hasClass('open') && !$gnavi_button_sp.hasClass('open')) {
    $gnavi_area_sp.addClass('open');
    $gnavi_button_sp.addClass('open');
    $gnavi_wrap.addClass('open');
  } else {
    closeAction();
  }
});

$gnavi_wrap.on('click', function () {
    closeAction();
});

function closeAction() {
  if ($gnavi_area.hasClass('open') && $gnavi_button.hasClass('open')) {
    $gnavi_area.removeClass('open');
    $gnavi_button.removeClass('open');
    $gnavi_wrap.removeClass('open');
  }
  if ($gnavi_area_sp.hasClass('open') && $gnavi_button_sp.hasClass('open')) {
    $gnavi_area_sp.removeClass('open');
    $gnavi_button_sp.removeClass('open');
    $gnavi_wrap.removeClass('open');
  }
}